<template>
  <div class="app-container calendar-list-container">
    <div class="filter-container">
      <div class="filter-item-left">
        <el-input
          :disabled="multiSearch"
          style="width: 400px; margin-right: 20px"
          class="filter-item"
          placeholder="请输入搜索内容"
          v-model="listQuery.keyword"
        ></el-input>
        <el-button
          class="filter-item"
          type="primary"
          :disabled="multiSearch"
          icon="el-icon-search"
          @click="getList()"
          >搜索</el-button
        >
      </div>
      <div class="filter-item-right">
        <el-button
          class="filter-item"
          @click="exportVisible = true"
          type="primary"
          icon="el-icon-edit-outline"
          >批量导出</el-button
        >
        <el-button
          class="filter-item"
          @click="handleCreate()"
          type="primary"
          icon="el-icon-edit-outline"
          >添加</el-button
        >
        <el-button
          class="filter-item"
          @click="handleMultiDownload()"
          type="primary"
          icon="el-icon-download"
          >批量下载</el-button
        >
        <el-button
          class="filter-item"
          @click="openDeleteConfirm(0)"
          type="danger"
          icon="el-icon-delete"
          >批量删除</el-button
        >
        <span class="filter-item" style="margin-left: 10px">二维码尺寸：</span>
        <el-select
          class="filter-item"
          v-model="qrcodeSize"
          placeholder="请选择"
        >
          <el-option
            v-for="item in qrOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </div>
      <!-- <el-button style="margin-left: 10px;" @click="handleCreate" type="primary" icon="el-icon-edit-outline">
                      添加
            </el-button>-->
    </div>
    <el-table
      :key="tableKey"
      :data="list"
      ref="table"
      :height="tableHeight"
      v-loading="listLoading"
      element-loading-text="加载中"
      border
      fit
      highlight-current-row
      @selection-change="handleSelectionChange"
      style="width: 100%; height: 80%"
    >
      <el-table-column type="selection" width="55"></el-table-column>
      <el-table-column
        type="index"
        align="center"
        label="序号"
        width="100"
      ></el-table-column>
      <el-table-column min-width="150" align="center" label="编号">
        <template slot-scope="scope">
          <span>{{ scope.row.serialNumber }}</span>
        </template>
      </el-table-column>
      <el-table-column min-width="150" align="center" label="二维码">
        <template slot-scope="scope">
          <a @click="handleShow(scope.row)">
            <qrcode
              :ref="'qc_' + scope.row.id"
              :value="scope.row.tempLink"
              :options="{ size: qrcodeSize }"
            ></qrcode>
          </a>
        </template>
      </el-table-column>
      <el-table-column min-width="150" align="center" label="状态">
        <template slot-scope="scope">
          <el-tag size="medium" :type="scope.row.status | statusFilter">{{
            scope.row.status | formatStata
          }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column min-width="60" align="center" label="创建时间">
        <template slot-scope="scope">
          <span>{{ scope.row.createdTime.replace("T", " ") }}</span>
        </template>
      </el-table-column>
      <el-table-column min-width="60" align="center" label="激活时间">
        <template slot-scope="scope">
          <span>{{ scope.row.activateTime }}</span>
        </template>
      </el-table-column>
      <el-table-column
        min-width="150"
        align="center"
        label="操作"
        class-name="small-padding fixed-width"
      >
        <template slot-scope="scope">
          <el-button
            :disabled="scope.row.status === 'yes'"
            size="mini"
            type="danger"
            icon="el-icon-delete"
            @click="openDeleteConfirm(1, scope.row)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <div class="pagination-container">
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="listQuery.page"
        :page-sizes="[10, 20, 30, 50]"
        :page-size="listQuery.limit"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      ></el-pagination>
    </div>

    <!-- <canvas id="box" width="200" height="250" style="border:1px solid #d3d3d3;"></canvas> -->
    <el-dialog
      :title="textMap[dialogStatus]"
      :visible.sync="dialogFormVisible"
      width="25%"
    >
      <el-form
        :rules="rules"
        ref="dataForm"
        :model="temp"
        status-icon
        label-position="right"
        label-width="60px"
        style="width: 90%; margin-left: 5%; margin-right: 5%"
      >
        <el-form-item
          label-width="14%"
          label="数量:"
          size="medium"
          prop="count"
        >
          <el-input
            type="number"
            v-model.number="temp.count"
            style="width: 50%; float: left"
          ></el-input>
        </el-form-item>
        <el-form-item label-width="14%" size="medium" label="企业名称:">
          <el-autocomplete
            placeholder="企业名称"
            v-model="temp.company_name"
            :fetch-suggestions="querySearchAsync"
            clearable
            style="width: 60%; float: left"
            @select="handleSelect"
          >
            <template slot-scope="{ item }">
              <span class="name">{{ item.company_name }}</span>
              <span
                class="addr"
                style="font-size: 12px; color: silver; float: right"
                >{{ item.credit_code }}</span
              >
            </template>
          </el-autocomplete>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer" style="margin-right: 6%">
        <el-button @click="dialogFormVisible = false">取消</el-button>
        <el-button
          v-if="dialogStatus == 'create'"
          type="primary"
          @click="createData('dataForm')"
          >确认</el-button
        >
        <el-button v-else type="primary">确认</el-button>
      </div>
    </el-dialog>

    <el-dialog
      title="二维码"
      :visible.sync="qrcodeVisible"
      width="300px"
      center
    >
      <div style="margin: 0px 30px">
        <qrcode
          ref="qrcode_large"
          :value="qrCode.code"
          :options="{ size: 200 }"
        ></qrcode>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="handleDownload()" icon="el-icon-download">下载</el-button>
      </span>
    </el-dialog>

    <el-dialog title="导出txt" :visible.sync="exportDialogVisible" width="40%">
      <h3>输入二维码编码区间:</h3>
      <el-form :inline="true" :model="form">
        <el-form-item label="起始编号">
          <el-input type="number" v-model.number="form.start"></el-input>
        </el-form-item>
        <el-form-item label="结束编号">
          <el-input type="number" v-model.number="form.end"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button>取 消</el-button>
        <el-button type="primary">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog
      title="批量导出"
      :visible.sync="exportVisible"
      width="70%"
      @closed="exportClose"
    >
      <div class="df aic jcc" style="padding: 20px">
        <el-form
          :inline="true"
          :model="formInline"
          label-width="120px"
          label-position="left"
          class="demo-form-inline"
        >
          <el-form-item label="起始编号">
            <el-input
              v-model="formInline.start"
              placeholder="起始编号"
              :maxlength="8"
            >
              <template slot="prepend" v-if="formInline.start&&prefixCode.start">{{
                prefixCode.start
              }}</template>
            </el-input>
          </el-form-item>
          <el-form-item label="">
            <div style="margin-top: 40px">至</div>
          </el-form-item>
          <el-form-item label="截止编号">
            <el-input
              v-model="formInline.end"
              placeholder="截止编号"
              :maxlength="8"
            >
              <template slot="prepend" v-if="formInline.end&&prefixCode.end">{{
                prefixCode.end
              }}</template>
            </el-input>
          </el-form-item>
          <el-form-item label="类型">
            <el-select v-model="formInline.type" placeholder="请选择类型">
              <el-option label="全部" :value="null"></el-option>
              <el-option label="未激活" value="no"></el-option>
              <el-option label="已激活" value="yes"></el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="exportVisible = false">取 消</el-button>
        <el-button type="primary" @click="handleExport"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import api from "../../../util/extra-api";
import axios from "../../../util/api";
export default {
  name: "qrcodeManage",
  data() {
    return {
      tableKey: 0,
      list: null, //表格数据
      total: null, //表格数据长度
      listLoading: true, //加载状态
      tableHeight: 450,
      listQuery: {
        page: 1,
        limit: 20,
        companyId: 0,
        keyword: "",
      },
      temp: {
        //弹框数据
        companyId: 0,
        count: null,
        shareCompanyId: null,
      },
      num8: "",
      num9: "",
      qrCode: {
        code: "",
        name: "",
      },
      qrcodeVisible: false,
      qrcodeData: null, //二维码数据
      heightData: null, //二维码导出框高度
      qrcodeLoading: true, //二维码导出框加载状态
      dialogFormVisible: false, //弹框显示与否
      dialogExportVisible: false, //二维码导出框显示与否
      dialogStatus: "", //弹框状态
      statusOptions: [
        //状态选项
        {
          value: "0",
          label: "未使用",
        },
        {
          value: "1",
          label: "已分发",
        },
        {
          value: "2",
          label: "已扫码",
        },
        {
          value: "3",
          label: "已打包",
        },
        {
          value: "4",
          label: "已激活",
        },
        {
          value: "9",
          label: "已作废",
        },
      ],
      textMap: {
        //弹框头部名称
        update: "编辑",
        create: "添加",
      },
      rules: {
        //输入框规则
        count: [
          {
            type: "number",
            required: true,
            message: "请填写数量",
            trigger: "blur",
          },
          // { validator: validPositiveInteger, trigger: "blur" }
        ],
        // prod_info: { required: true, message: "请选择产品", trigger: "blur" },
        // tp_id: {required: true, message: "请选择模板", trigger: "blur"}
      },
      prodOptions: [], //产品数据
      traceOptions: [],
      searchValue: {
        type: "",
        data: "",
      },
      multiSearch: false,
      options: [
        {
          value: "count",
          label: "二维码编号",
        },
        {
          value: "status",
          label: "状态",
        },
        {
          value: "prod_id",
          label: "产品",
        },
      ],
      qrcodeSize: 100, //默认的二维码尺寸
      qrOptions: [
        //二维码尺寸的选择
        {
          value: 100,
          label: "100 X 100",
        },
        {
          value: 200,
          label: "200 X 200",
        },
      ],
      multipleSelection: [],
      exportDialogVisible: false,
      form: {
        start: null,
        end: null,
      },
      exportVisible: false,
      formInline: {
        companyId: null,
        start: "",
        end: "",
        type: null,
      },
    };
  },
  filters: {
    // el-tag类型转换
    statusFilter(status) {
      const statusMap = {
        no: "info",
        yes: "success",
      };
      return statusMap[status];
    },
    // 状态显示转换
    formatStata(status) {
      const statusMap = {
        no: "未激活",
        yes: "已激活",
      };
      return statusMap[status];
    },
    //编号去零
    replaceZero(value) {
      return value.replace(/\b(0+)/gi, "");
    },
  },
  mounted() {
    this.formInline.companyId = localStorage.getItem("company_id");
    this.listQuery.companyId = localStorage.getItem("company_id");
    this.temp.companyId = localStorage.getItem("company_id");
    this.getList();
  },
  computed: {
    prefixCode() {
      let start = "";
      let end = "";
      for (let i = 0; i < 8 - this.formInline.start.length; i++) {
        start += "0";
      }
      for (let i = 0; i < 8 - this.formInline.end.length; i++) {
        end += "0";
      }
      return {
        start,
        end,
      };
    },
  },
  methods: {
    exportClose() {
      this.formInline.start = "";
      this.formInline.end = "";
      this.formInline.type = null;
    },
    handleExport() {
      api
        .fileget("/v1/wx/activationCode/exportFile", {
          companyId: this.formInline.companyId,
          startCode: this.formInline.start,
          endCode: this.formInline.end,
          status: this.formInline.type,
        })
        .then((res) => {
          if (res.code === 200) {
            this.formInline.start = "";
            this.formInline.end = "";
            this.formInline.type = null;
            this.exportVisible = false;
          }
        });
    },
    //根据库房名称查询信息
    querySearchAsync(queryString, cb) {
      if (queryString) {
        axios.get("/pc/company/signList/2",{company_name:this.temp.company_name}).then((response) => {
          let results = response.data.company_list;
          cb(results);
        });
      } else {
        cb([]);
      }
    },
    handleSelect(item) {
      this.temp.company_id = item.company_id;
      this.temp.company_name = item.company_name;
      this.temp.shareCompanyId = item.company_id;
    },
    handleShow(row) {
      this.qrcodeVisible = true;
      this.qrCode.code = row.tempLink;
      this.qrCode.name = row.serialNumber;
    },
    // 表格单页数据显示量
    handleSizeChange(val) {
      this.listQuery.limit = val;
      this.getList();
    },
    // 换页
    handleCurrentChange(val) {
      this.listQuery.page = val;
      this.getList();
    },
    getList() {
      api.get("/v1/wx/activationCode/list", this.listQuery).then((res) => {
        if (res.code === 200) {
          //console.log("res.data",res.data)
          this.tableHeight =
            window.innerHeight - this.$refs.table.$el.offsetTop - 85;
          this.total = res.total;
          this.listLoading = false;
          this.list = res.data;
        }
      });
    },
    handleCreate() {
      this.dialogStatus = "create";
      this.dialogFormVisible = true;
      this.$nextTick(() => {
        this.$refs["dataForm"].clearValidate();
      });
    },
    createData() {
      api
        .post("/v1/wx/activationCode/generateActivationCode", this.temp)
        .then((res) => {
          if (res.code === 200) {
            this.getList();
            this.dialogFormVisible = false;
          }
        });
    },
    // 删除操作
    handleDelete(row) {
      let data = {
        id: row.id,
      };
      api
        .post("/v1/wx/activationCode/deleteActivationCode", data)
        .then((response) => {
          this.$notify({
            title: "成功",
            message: "删除成功",
            type: "success",
            duration: 2000,
          });
          this.getList();
        })
        .catch(() => {
          this.$notify({
            title: "失败",
            message: "删除失败",
            type: "error",
            duration: 2000,
          });
        });
    },
    handleMultiDelete() {
      let data = {
        deleteIds: this.multipleSelection.map((m) => m.id),
      };
      //console.log("data",data)
      api
        .post("/v1/wx/activationCode/batchDeleteActivationCode", data)
        .then((response) => {
          this.$notify({
            title: "成功",
            message: "删除成功",
            type: "success",
            duration: 2000,
          });
          this.getList();
        })
        .catch(() => {
          this.$notify({
            title: "失败",
            message: "删除失败",
            type: "error",
            duration: 2000,
          });
        });
    },
    // 打开删除确认框
    openDeleteConfirm(type, row) {
      if (type === 0) {
        this.$confirm("确定删除勾选的数据?", "删除", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "error",
          center: true,
          beforeClose: (action, instance, done) => {
            if (action === "confirm") {
              this.handleMultiDelete();
              done();
            } else {
              done();
            }
          },
        }).catch(() => {
          this.$notify({
            title: "取消",
            message: "已取消删除",
            type: "info",
            duration: 1000,
          });
        });
      } else {
        this.$confirm("确定删除此条数据?", "删除", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "error",
          center: true,
          beforeClose: (action, instance, done) => {
            if (action === "confirm") {
              this.handleDelete(row);
              done();
            } else {
              done();
            }
          },
        }).catch(() => {
          this.$notify({
            title: "取消",
            message: "已取消删除",
            type: "info",
            duration: 1000,
          });
        });
      }
    },
    //下载功能
    saveFile(data, filename) {
      let save_link = document.createElement("a");
      save_link.href = data;
      save_link.download = filename;
      save_link.click();
    },
    //下载二维码
    handleDownload() {
      let myCanvas = this.$refs["qrcode_large"].$el;
      var mcName = this.qrCode.name.replace(/\b(0+)/gi, "");
      var canvas = document.createElement("canvas");
      var size = 200;
      canvas.width = size;
      canvas.height = size + 30;
      var ctx = canvas.getContext("2d");
      var canvasBottom = document.createElement("canvas");
      var size = 200;
      canvasBottom.width = size;
      canvasBottom.height = size + 30;
      var cb = canvasBottom.getContext("2d");
      cb.fillStyle = "#fff";
      cb.fillRect(0, 0, canvas.width, canvas.height);
      ctx.drawImage(
        canvasBottom,
        0,
        0,
        canvasBottom.width,
        canvasBottom.height
      );
      ctx.drawImage(myCanvas, 0, 0, size, size);
      ctx.font = "20px Arial";
      ctx.textAlign = "center";
      ctx.fillText(mcName, 100, 220);
      const type = "image/png";
      let imgData = canvas.toDataURL(type).replace(type, "image/octet-stream");
      let filename = mcName + ".png";
      this.saveFile(imgData, filename);
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    handleMultiDownload() {
      if (this.qrcodeSize == 100) {
        this.$message({
          showClose: true,
          message:
            "为了保证下载二维码的质量，请在批量下载时修改二维码尺寸为200X200",
          type: "warning",
          duration: 5000,
        });
      } else {
        if (this.multipleSelection.length > 0) {
          this.multipleSelection.forEach((item) => {
            let multipleCanvas = this.$refs["qc_" + item.id].$el;
            var mcName1 = item.serialNumber.replace(/\b(0+)/gi, "");
            var canvas1 = document.createElement("canvas");
            var size = 200;
            canvas1.width = size;
            canvas1.height = size + 30;
            var ctx = canvas1.getContext("2d");
            var canvasBottom1 = document.createElement("canvas");
            var size = 200;
            canvasBottom1.width = size;
            canvasBottom1.height = size + 30;
            var cb1 = canvasBottom1.getContext("2d");
            cb1.fillStyle = "#fff";
            cb1.fillRect(0, 0, canvas1.width, canvas1.height);
            ctx.drawImage(
              canvasBottom1,
              0,
              0,
              canvasBottom1.width,
              canvasBottom1.height
            );
            ctx.drawImage(multipleCanvas, 0, 0, size, size);
            ctx.font = "20px Arial";
            ctx.textAlign = "center";
            ctx.fillText(mcName1, 100, 220);
            const type = "image/png";
            let imgData1 = canvas1
              .toDataURL(type)
              .replace(type, "image/octet-stream");
            let filename1 = mcName1 + ".png";
            this.saveFile(imgData1, filename1);
          });
        } else {
          this.$message({
            message: "请选择二维码",
            type: "warning",
            center: true,
          });
        }
      }
    },
  },
};
</script>

<style lang="scss">
.app-container {
  padding: 20px;
}
.filter-container {
  padding-bottom: 10px;
  .filter-item {
    display: inline-block;
    vertical-align: middle;
    margin-bottom: 10px;
  }
}
.filter-item-right {
  display: block;
  float: right;
  margin-bottom: 10px;
}
.filter-item-left {
  display: block;
  float: left;
  margin-bottom: 10px;
}
.multisearch {
  margin: 10px 0px;
}
.pagination-container {
  margin-top: 30px;
}
</style>
